import ServiceQuotes from "../../service/quotes/ServiceQuotes.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  workshopQuotesCount: null,
  workshopQuotesList: [],
  workshopQuoteTaskId: null,
  workshopQuoteJobCreateTaskId: null,
  attachWorkshopQuoteToJobTaskId: null,
};

export const mutations = {
  // workshop quotes
  SET_WORKSHOP_QUOTES_LIST(state, data) {
    state.workshopQuotesList = data;
  },

  SET_WORKSHOP_QUOTES_COUNT(state, count) {
    state.workshopQuotesCount = count;
  },

  ADD_WORKSHOP_QUOTE(state, workshopQuoteObj) {
    state.workshopQuotesList.unshift(workshopQuoteObj);
  },

  UPDATE_WORKSHOP_QUOTE(state, workshopQuoteObj) {
    state.workshopQuotesList = state.workshopQuotesList.map((element) => {
      if (element.id === workshopQuoteObj.id) {
        element = JSON.parse(JSON.stringify(workshopQuoteObj));
      }
      return element;
    });
  },

  DELETE_WORKSHOP_QUOTE(state, quoteUUID) {
    state.workshopQuotesList = state.workshopQuotesList.filter((element) => {
      if (element.quote_uuid != quoteUUID) {
        return element;
      }
    });
  },

  SAVE_QUOTE_TASK_ID(state, taskId) {
    state.workshopQuoteTaskId = taskId;
  },

  CLEAR_QUOTE_TASK_ID(state) {
    state.workshopQuoteTaskId = null;
  },

  SAVE_QUOTE_JOB_CREATE_TASK_ID(state, taskId) {
    state.workshopQuoteJobCreateTaskId = taskId;
  },

  CLEAR_QUOTE_JOB_CREATE_TASK_ID(state) {
    state.workshopQuoteJobCreateTaskId = null;
  },

  SAVE_ATTACH_QUOTE_TO_JOB_TASK_ID(state, taskId) {
    state.attachWorkshopQuoteToJobTaskId = taskId;
  },

  CLEAR_ATTACH_QUOTE_TO_JOB_TASK_ID(state) {
    state.attachWorkshopQuoteToJobTaskId = null;
  },
};

export const actions = {
  // workshop quotes
  getWorkshopQuotesList(
    { commit },
    { perPage, page, searchQuery, status, universalSearch }
  ) {
    return ServiceQuotes.getWorkshopQuotesList(
      perPage,
      page,
      searchQuery,
      status
    )
      .then((resp) => {
        if (universalSearch !== true) {
          commit("SET_WORKSHOP_QUOTES_LIST", resp.data.results);
          commit("SET_WORKSHOP_QUOTES_COUNT", resp.data.count);
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopQuotesListWithoutPagination({}, { searchQuery }) {
    return ServiceQuotes.getWorkshopQuotesListWithoutPagination(searchQuery)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  createWorkshopQuote({ commit }, formData) {
    return ServiceQuotes.createWorkshopQuote(formData)
      .then((resp) => {
        commit("ADD_WORKSHOP_QUOTE", resp.data);

        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Quote created successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.quote_number) {
            message = "Quote Number: " + err.response.data.quote_number;
          } else if (err.response.data.reference) {
            message = "Reference: " + err.response.data.reference;
          } else if (err.response.data.currency) {
            message = "Currency: " + err.response.data.currency;
          } else if (err.response.data.line_amount_type) {
            message = "Line Amount Type: " + err.response.data.line_amount_type;
          } else if (err.response.data.include_discount) {
            message = "Include Discount: " + err.response.data.include_discount;
          } else if (err.response.data.due_date) {
            message = "Due Date: " + err.response.data.due_date;
          } else if (err.response.data.issue_date) {
            message = "Issue Date: " + err.response.data.issue_date;
          } else if (err.response.data.status) {
            message = "Status: " + err.response.data.status;
          } else if (err.response.data.note) {
            message = "Note: " + err.response.data.note;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          } else if (err.response.data.quotelineitem_set.length > 0) {
            for (const errObj of err.response.data.quotelineitem_set) {
              if (Object.keys(errObj).length > 0) {
                if (errObj.seq_no) {
                  message = "Seq No.: " + errObj.seq_no;
                } else if (errObj.item_type) {
                  message = "Item: " + errObj.item_type;
                } else if (errObj.product) {
                  message = "Item: " + errObj.product;
                } else if (errObj.general_service) {
                  message = "Item: " + errObj.general_service;
                } else if (errObj.description) {
                  message = "Description: " + errObj.description;
                } else if (errObj.quantity) {
                  message = "Quantity: " + errObj.quantity;
                } else if (errObj.cost_price) {
                  message = "Cost Price: " + errObj.cost_price;
                } else if (errObj.price) {
                  message = "Price: " + errObj.price;
                } else if (errObj.tax_rate) {
                  message = "Tax Rate: " + errObj.tax_rate;
                } else if (errObj.discount_amount) {
                  message = "Discount Amount: " + errObj.discount_amount;
                } else if (errObj.total_amount) {
                  message = "Amount: " + errObj.total_amount;
                }
                break;
              }
            }
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  duplicateWorkshopQuote({ commit }, quoteUUID) {
    return ServiceQuotes.duplicateWorkshopQuote(quoteUUID)
      .then((resp) => {
        commit("ADD_WORKSHOP_QUOTE", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Quote duplicated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  retrieveWorkshopQuote({}, quoteUUID) {
    return ServiceQuotes.retrieveWorkshopQuote(quoteUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  retrieveWorkshopQuoteCustomerView({}, { quoteUUID }) {
    return ServiceQuotes.retrieveWorkshopQuoteCustomerView(quoteUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateWorkshopQuote({ commit }, { quoteUUID, formData }) {
    return ServiceQuotes.updateWorkshopQuote(quoteUUID, formData)
      .then((resp) => {
        commit("UPDATE_WORKSHOP_QUOTE", resp.data);

        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Quote updated successfully",
        });

        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.vehicle) {
            message = "Vehicle: " + err.response.data.vehicle;
          } else if (err.response.data.customer) {
            message = "Customer: " + err.response.data.customer;
          } else if (err.response.data.quote_number) {
            message = "Quote Number: " + err.response.data.quote_number;
          } else if (err.response.data.reference) {
            message = "Reference: " + err.response.data.reference;
          } else if (err.response.data.currency) {
            message = "Currency: " + err.response.data.currency;
          } else if (err.response.data.line_amount_type) {
            message = "Line Amount Type: " + err.response.data.line_amount_type;
          } else if (err.response.data.include_discount) {
            message = "Include Discount: " + err.response.data.include_discount;
          } else if (err.response.data.expiry_date) {
            message = "Expiry Date: " + err.response.data.expiry_date;
          } else if (err.response.data.issue_date) {
            message = "Issue Date: " + err.response.data.issue_date;
          } else if (err.response.data.status) {
            message = "Status: " + err.response.data.status;
          } else if (err.response.data.note) {
            message = "Note: " + err.response.data.note;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          } else if (err.response.data.quotelineitem_set.length > 0) {
            for (const errObj of err.response.data.quotelineitem_set) {
              if (Object.keys(errObj).length > 0) {
                if (errObj.seq_no) {
                  message = "Seq No.: " + errObj.seq_no;
                } else if (errObj.item_type) {
                  message = "Item: " + errObj.item_type;
                } else if (errObj.product) {
                  message = "Item: " + errObj.product;
                } else if (errObj.general_service) {
                  message = "Item: " + errObj.general_service;
                } else if (errObj.description) {
                  message = "Description: " + errObj.description;
                } else if (errObj.quantity) {
                  message = "Quantity: " + errObj.quantity;
                } else if (errObj.cost_price) {
                  message = "Cost Price: " + errObj.cost_price;
                } else if (errObj.price) {
                  message = "Price: " + errObj.price;
                } else if (errObj.tax_rate) {
                  message = "Tax Rate: " + errObj.tax_rate;
                } else if (errObj.discount_amount) {
                  message = "Discount Amount: " + errObj.discount_amount;
                } else if (errObj.total_amount) {
                  message = "Amount: " + errObj.total_amount;
                }
                break;
              }
            }
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteWorkshopQuote({ commit }, quoteUUID) {
    return ServiceQuotes.deleteWorkshopQuote(quoteUUID)
      .then((resp) => {
        commit("DELETE_WORKSHOP_QUOTE", quoteUUID);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Quote deleted successfully!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  attachWorkshopQuoteToOpenJob({ commit }, { quoteUUID, jobUUID }) {
    return ServiceQuotes.attachWorkshopQuoteToOpenJob(quoteUUID, jobUUID)
      .then((resp) => {
        commit("SAVE_ATTACH_QUOTE_TO_JOB_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Attaching quote to the job. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  checkAttachWorkshopQuoteToOpenJobTaskStatus(
    { commit },
    { quoteUUID, formData }
  ) {
    return ServiceQuotes.checkAttachWorkshopQuoteToOpenJobTaskStatus(
      quoteUUID,
      formData
    )
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_ATTACH_QUOTE_TO_JOB_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  shareWorkshopQuoteWithCustomer({}, { quoteUUID, formData }) {
    return ServiceQuotes.shareWorkshopQuoteWithCustomer(quoteUUID, formData)
      .then((resp) => {
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Sending quote URL to customer!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  markWorkshopQuoteAccepted({}, quoteUUID) {
    return ServiceQuotes.markWorkshopQuoteAccepted(quoteUUID)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Marked quote as accepted!",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  markWorkshopQuoteDeclined({}, quoteUUID) {
    return ServiceQuotes.markWorkshopQuoteDeclined(quoteUUID)
      .then((resp) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Marked quote as declined!",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  createJobFromWorkshopQuote({ commit }, quoteUUID) {
    return ServiceQuotes.createJobFromWorkshopQuote(quoteUUID)
      .then((resp) => {
        commit("SAVE_QUOTE_JOB_CREATE_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Creating job from quote. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  checkJobFromWorkshopQuoteCreateTaskStatus(
    { commit },
    { quoteUUID, formData }
  ) {
    return ServiceQuotes.checkJobFromWorkshopQuoteCreateTaskStatus(
      quoteUUID,
      formData
    )
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_QUOTE_JOB_CREATE_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopQuoteDurationInputType({}, quoteUUID) {
    return ServiceQuotes.getWorkshopQuoteDurationInputType(quoteUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopQuoteFormData({}) {
    return ServiceQuotes.getWorkshopQuoteFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateWorkshopQuotePDF({ commit }, { quoteUUID, formData }) {
    return ServiceQuotes.generateWorkshopQuotePDF(quoteUUID, formData)
      .then((resp) => {
        commit("SAVE_QUOTE_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating quote pdf. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkPDFGenerationTaskStatus({ commit }, { quoteUUID, formData }) {
    return ServiceQuotes.checkPDFGenerationTaskStatus(quoteUUID, formData)
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_QUOTE_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
