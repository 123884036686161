import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
});

const urlList = {
  urlRoot: "/api/v1/",
};

export default {
  // workshop quotes
  getWorkshopQuotesList(perPage, page, searchQuery, status) {
    let url =
      urlList.urlRoot +
      "workshop/quotes/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    if (status) {
      url += "&status=" + status;
    }

    return apiClient.get(url);
  },

  getWorkshopQuotesListWithoutPagination(searchQuery) {
    let url = urlList.urlRoot + "workshop/quotes/";

    if (searchQuery) {
      url += "?search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  createWorkshopQuote(formData) {
    let url = urlList.urlRoot + "workshop/quotes/";
    return apiClient.post(url, formData);
  },

  retrieveWorkshopQuote(quoteUUID) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/`;
    return apiClient.get(url);
  },

  retrieveWorkshopQuoteCustomerView(quoteUUID) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/customer-view/`;
    return apiClient.get(url);
  },

  updateWorkshopQuote(quoteUUID, formData) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/`;
    return apiClient.patch(url, formData);
  },

  deleteWorkshopQuote(quoteUUID) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/`;
    return apiClient.delete(url);
  },

  duplicateWorkshopQuote(quoteUUID) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/duplicate/`;
    return apiClient.post(url);
  },

  attachWorkshopQuoteToOpenJob(quoteUUID, jobUUID) {
    let url =
      urlList.urlRoot +
      `workshop/quotes/${quoteUUID}/attach-to-job/${jobUUID}/`;
    return apiClient.post(url);
  },

  checkAttachWorkshopQuoteToOpenJobTaskStatus(quoteUUID, formData) {
    let url =
      urlList.urlRoot +
      `workshop/quotes/${quoteUUID}/attach-to-job-task-status/`;
    return apiClient.post(url, formData);
  },

  shareWorkshopQuoteWithCustomer(quoteUUID, formData) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/share/customer/`;
    return apiClient.post(url, formData);
  },

  markWorkshopQuoteAccepted(quoteUUID) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/mark-accepted/`;
    return apiClient.post(url);
  },

  markWorkshopQuoteDeclined(quoteUUID) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/mark-declined/`;
    return apiClient.post(url);
  },

  createJobFromWorkshopQuote(quoteUUID) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/create-job/`;
    return apiClient.post(url);
  },

  checkJobFromWorkshopQuoteCreateTaskStatus(quoteUUID, formData) {
    let url =
      urlList.urlRoot + `workshop/quotes/${quoteUUID}/create-job-task-status/`;
    return apiClient.post(url, formData);
  },

  getWorkshopQuoteDurationInputType(quoteUUID) {
    let url =
      urlList.urlRoot + `workshop/quotes/${quoteUUID}/duration-input-type/`;
    return apiClient.get(url);
  },

  getWorkshopQuoteFormData() {
    let url = urlList.urlRoot + "workshop/quotes/form-data/";
    return apiClient.get(url);
  },

  generateWorkshopQuotePDF(quoteUUID, formData) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/generate-pdf/`;
    return apiClient.post(url, formData);
  },

  checkPDFGenerationTaskStatus(quoteUUID, formData) {
    let url = urlList.urlRoot + `workshop/quotes/${quoteUUID}/pdf-task-status/`;
    return apiClient.post(url, formData);
  },
};
